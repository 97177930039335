<template>
  <div class="shoppingCartPageBox">
    <!-- 购物车有商品的时候 -->
    <div v-if="shoppingData.length > 0">
      <p class="quantityCommodity">
        全部商品&nbsp;&nbsp;{{ total }}
        <el-popover placement="top-start" title="平台开市时间" width="100" trigger="hover" :content="`${$store.state.tradeTimeRule.tradeDay === '00' ? '工作日：' : '每天：' }`+ `${$store.state.tradeTimeRule.state === 1 ? $store.state.tradeTimeRule.openTime + '-' + $store.state.tradeTimeRule.closeTime : '00:00-23:59'}`">
          <span slot="reference" :class="{'not-buy': !$store.state.tradeTimeState}">{{ $store.state.tradeTimeState ? '正常营业中' : '非营业时间' }}<i class="el-icon-warning" /></span>
        </el-popover>
      </p>
      <p class="tipBox">
        <img src="@/assets/icon/deng.png" alt="deng">
        温馨提示：以下商品金额不含运费，如选商家配送服务，可向商家咨询运费
      </p>
      <!-- 购物车的表头 -->
      <div class="tableHeader">
        <!-- <el-checkbox v-model="allChecked" @change="allListchecked">
          全选
        </el-checkbox> -->
        <span class="commodityInformation">商品信息</span>
        <span class="price">含税单价</span>
        <span class="weight">重量(吨)</span>
        <span class="subtotal">小计金额</span>
        <span class="operate">操作</span>
      </div>
      <!-- 购物车的商品列表 -->
      <div v-for="(item, key) in shoppingData" :key="key" class="tableBody">
        <!-- 选择公司下所有的订单 -->
        <p class="cmpName">
          <!-- <el-checkbox v-model="item.checked" @change="changeCmpChecked"> -->
          {{ item.cmpName }}
          <!-- </el-checkbox> -->
        </p>
        <!-- 公司下所有的订单 -->
        <div class="orderList">
          <div v-for="(val) in item.respTradeShoppingCartList" :key="val.id" class="list">
            <el-radio v-if="val.goodsStatus === '01'" v-model="checkedId" :label="val.id" @change="getGoods(val)">
              {{ val.a }}
            </el-radio>
            <!-- <el-checkbox v-if="val.goodsStatus === '01'" v-model="val.checked" class="checked" @change="changeItemChecked" /> -->
            <span v-if="val.goodsStatus === '02'" class="invalid">失效</span>
            <!-- 图片 -->
            <div class="imageBox">
              <img class="cImage" :src="$fileUrl + val.productMainUrl" alt="图片">
              <span v-if="val.goodsStatus === '02'" class="soldOut">商品已下架</span>
            </div>
            <!-- 商品详情 -->
            <div class="commodityDetails">
              <h3 class="title">
                {{ val.goodsName ? val.goodsName.split(' ')[0] : '' }}<span>{{ val.goodsMaterialName }}</span><span>{{ val.goodsSpecName }}</span>
              </h3>
              <p class="inventory">
                {{ val.warehouseName }}<i />库存{{ val.availableListingWeight }}吨
              </p>
              <p class="minimumOrderQuantity">
                起订量<span>≥{{ val.minOrderQuantity }}吨</span>
              </p>
            </div>
            <p class="price">
              ￥{{ val.unitPriceIncludingTax }}元/吨
            </p>
            <el-input-number v-model="val.purchaseWeight" :precision="3" :step="1" :min="0.001" :max="val.availableListingWeight" @change="(num) => changePurchaseWeight(num, val)" />
            <b class="subtotalAmount">
              ￥{{ val.totalPrice }}
            </b>
            <span class="remove" @click="deleteGoods(val)">删除</span>
          </div>
        </div>
      </div>
      <div class="car-footer">
        <div>共<b class="subtotalAmount">{{ checkedObj ? checkedObj.purchaseWeight : 0 }}</b>吨</div>
        <div>总价<b class="subtotalAmount">{{ checkedObj ? checkedObj.totalPrice : 0 }}</b>元</div>
        <el-tooltip class="item" effect="dark" content="不含运费及其他服务费" placement="top-start">
          <i class="icon deng" />
        </el-tooltip>
        <div :class="['button', { 'active' : checkedObj && $store.state.tradeTimeState}]" @click="clickPlaceOrder">
          下单
        </div>
      </div>
    </div>
    <div v-else class="noGoodsBox">
      <img class="noGoodsImg" src="@/assets/image/cat.png" alt="cat">
      <div class="textDescription">
        <p>购物车空空的哦~快去选购吧~</p>
        <router-link :to="{path:'/'}">
          去选购>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { tradeShoppingCartList, tradeShoppingCartUpdate, goodsHallDetailDetailSpotHallGoods } from '@/http/shoppingCar'
import { NumberRoundUp } from '@/unit/index'

export default {
  data() {
    return {
      // allChecked: false,
      num1: 5,
      shoppingData: {},
      // 选中对象
      checkedObj: null,
      checkedId: '',
      total: 0, // 总条数
      userInfo: {}
    }
  },
  created() {
    this.$store.dispatch('getTradeTimeState')
    this.$store.dispatch('getTradeTime')
    this.getCarList()
    if (sessionStorage.cartCheckedId) {
      this.checkedId = sessionStorage.cartCheckedId
    }
    if (
      localStorage.getItem(`mallFrontDeskToken${this.$store.state.userCmpRole}`)
    ) {
      this.userInfo = JSON.parse(localStorage.getItem(`mallFrontDeskUserInfo${this.$store.state.userCmpRole}`)) || {}
    }
  },
  methods: {
    getGoods(data) {
      this.checkedObj = data
      sessionStorage.cartCheckedId = this.checkedId
    },
    // 获取购物车列表
    getCarList() {
      tradeShoppingCartList(res => {
        this.shoppingData = res.data
        this.total = 0
        this.shoppingData.map((item) => {
          this.total += item.respTradeShoppingCartList.length
          item.respTradeShoppingCartList.map((jtem) => {
            // jtem.checked = false
            if (jtem.unitPriceIncludingTax && jtem.purchaseWeight)jtem.totalPrice = NumberRoundUp((jtem.unitPriceIncludingTax * jtem.purchaseWeight), 2)
          })
          return item
        })
        this.shoppingData.forEach((item) => {
          item.respTradeShoppingCartList.forEach((jtem) => {
            if (jtem.id === this.checkedId) this.getGoods(jtem)
          })
        })
      })
    },
    // 添加或减少数量
    changePurchaseWeight(num, val) {
      if (val.availableListingWeight < num) return this.$message.warning('商品库存不足')
      tradeShoppingCartUpdate({ id: val.id, num }, res => {
        this.getCarList()
      })
    },
    // 删除
    async deleteGoods({ id }) {
      const confirm = await this.$confirm(`确认要删除该挂牌商品吗？`, '提示', { type: 'warning' }).catch(err => err)
      if (confirm !== 'confirm') return
      goodsHallDetailDetailSpotHallGoods(id, res => {
        this.$message.success('删除成功')
        this.getCarList()
      })
    },
    // 下单
    async clickPlaceOrder() {
      if (!this.$store.state.tradeTimeState) return this.$message.error('不在营业时间范围内，不能完成下单！')
      if (!this.checkedObj) return this.$message.warning('请选择商品')
      switch (this.userInfo.cmpState) {
        case '01':
          this.infoMakeup()
          return
        case '-1':
          this.infoMakeup()
          return
        default:
        {
          if (this.checkedObj.purchaseWeight < this.checkedObj.minOrderQuantity) return this.$message.warning('购买数量低于起订量，不可购买')
          const confirm = await this.$confirm(`确认下单吗？`, '提示', { type: 'warning' }).catch(err => err)
          if (confirm !== 'confirm') return
          this.$router.push({ path: '/orderManagement/index', query: { id: this.checkedObj.id, type: 'cartBuy' }})
          break
        }
      }
    },
    infoMakeup() {
      this.$confirm(
        `亲爱的${this.userInfo.userName || this.userInfo.orgName}用户, 为了您能正常使用平台提供的功能，请尽快完善公司信息`,
        '信息未完善',
        {
          confirmButtonText: '立即完善',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        window.open(`${this.$projectUrl}login?token=${this.token}&cmpRole=${this.$store.state.userCmpRole}&path=/orgStructure/enterpriseInformation`, '_blank')
      })
        .catch(() => {})
    }
    // 单项数据选择/反选
    // changeItemChecked() {
    // this.shoppingData.map((item) => {
    //   const index = item.respTradeShoppingCartList.findIndex((val) => val.checked === false)
    //   if (index !== -1) {
    //     item.checked = false
    //     this.placeAnOrder = false
    //   } else {
    //     item.checked = true
    //     this.placeAnOrder = true
    //   }
    // })
    // this.funCheckedList()
    // },
    // 按公司 全选/反选
    // changeCmpChecked() {
    //   this.$forceUpdate()
    //   this.shoppingData.map((item) => {
    //     if (item.checked === true) {
    //       item.respTradeShoppingCartList.map((val) => (val.checked = true))
    //       this.placeAnOrder = true
    //     } else {
    //       item.respTradeShoppingCartList.map((val) => (val.checked = false))
    //       this.placeAnOrder = false
    //     }
    //   })
    //   this.funCheckedList()
    // },
    // all 全选/反选
    // allListchecked(val) {
    //   if (val === true) {
    //     this.shoppingData.map((item) => {
    //       item.checked = true
    //       item.respTradeShoppingCartList.map((jtem) => {
    //         jtem.checked = true
    //       })
    //       return item
    //     })
    //   } else {
    //     this.shoppingData.map((item) => {
    //       item.checked = false
    //       item.respTradeShoppingCartList.map((jtem) => {
    //         jtem.checked = false
    //       })
    //       return item
    //     })
    //   }
    // },
    // 页面全选/反选
    // funCheckedList() {
    //   const index = this.shoppingData.findIndex((val) => val.checked === false)
    //   // eslint-disable-next-line no-return-assign
    //   if (index !== -1) return this.allChecked = false
    //   this.allChecked = true
    // },
  }
}
</script>

<style lang="scss" scoped>
.shoppingCartPageBox {
  .el-radio {
    margin-left: 30px;
  }
  width: $centerPlateWidth;
  margin: auto;
 .noGoodsBox{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 276px 0;
  .textDescription{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    color: $mainFont;
    font-size: 14px;
    height: 59px;
    a{
      text-decoration: underline;
      color: $themeColors;
    }
    p{
      padding-top: 6px;
    }
  }
  .noGoodsImg{
    height: 55px;
    width: 59px;
    margin-right: 22px;
  }
 }
  .tableBody {
    .orderList {
      border: 1px solid $borderColor3;

      .list {
        display: flex;
        align-items: center;
        padding: 21px 0;
        border-bottom: 1px solid $borderColor3;
        .subtotalAmount{
          color: #ff0000;
          font-size: 12px;
          font-weight: 900;
          width: 160px;
        }
        .remove{
          font-size: 12px;
          color: $mainFont;
          cursor: pointer;
          &:hover {
            color: #ff0000;
          }
        }
        .place-order {
          font-size: 12px;
          color: #fff;
          cursor: pointer;
          margin-left: 20px;
          background-color: #D7D7D7;
          padding: 2px 5px;
        }
        .el-input-number{
          height: 33px;
          width: 160px;
          margin-right: 72px;
          /deep/.el-input-number__decrease,/deep/.el-input-number__increase{
            width: 33px;
            height: 31px;
            line-height: 31px;
            top: 3px;
          }
          /deep/.el-input{
            height: 33px;
            .el-input__inner{
              height: 33px;
            }
          }
          /deep/.el-input-number__decrease:hover,/deep/.el-input-number__increase:hover{
            color: $mainFont;
          }
          /deep/.el-input-number__decrease:hover .el-input__inner,/deep/.el-input-number__increase:hover .el-input__inner{
            border: 1px solid $borderColor3;
          }
        }
        .price{
          width: 148px;
          font-size: 12px;
          color: $mainFont;
        }
        .commodityDetails {
          display: flex;
          flex-direction: column;
          height: 80px;
          width: 400px;
          align-items: flex-start;
          justify-content: space-around;
          font-size: 12px;
          .minimumOrderQuantity{
            color: $fontColor1;
            span{
              margin-left: 8px;
            }
          }
          .inventory{
            color: $fontColor1;
            i{
              display: inline-block;
              height: 10px;
              width: 1px;
              background-color: $fontColor1;
              margin: 0 6px;
            }
          }
          .title{
            color: $mainFont;
            font-weight: 700;
            span{
              font-weight: 500;
              margin-left: 6px;
            }
          }
        }

        .checked {
          padding: 0 31px 0 35px;
        }

        .imageBox {
          position: relative;
          margin-right: 15px;
          width: 80px;
          height: 80px;
          .soldOut{
            position: absolute;
            top: 0;
            right: 0;
            font-size: 12px;
            width: 100%;
            height: 100%;
            line-height: 80px;
            text-align: center;
            color: #fff;
            background-color: rgba(0,0,0,.3);
          }
          .cImage {
            width: 100%;
            height: 100%;
            display: block;
          }
        }

      }

      .list:last-of-type {
        border-bottom: 0;
      }
    }

    .cmpName {
      padding: 18px 0 10px 15px;
      font-size: 12px;
      color: $mainFont;
    }
  }

  .tableHeader {
    display: flex;
    align-items: center;
    border: 1px solid $borderColor3;
    height: 42px;
    box-sizing: border-box;
    background-color: $background;
    font-size: 12px;
    color: $mainFont;

    .subtotal {
      width: 158px;
    }

    .weight {
      width: 168px;
    }

    .price {
      width: 168px;
    }

    .commodityInformation {
      width: 407px;
      padding-left: 178px;
    }

    .el-checkbox {
      margin-left: 15px;
      width: 178px;
    }
  }

  .tipBox {
    font-size: 12px;
    color: #35383D;
    display: flex;
    align-items: center;
    padding-bottom: 12px;

    img {
      margin-right: 7px;
      height: 25px;
      width: 25px;
    }
  }

  .quantityCommodity {
    padding: 27px 0 12px;
    color: $mainFont;
    font-size: 14px;

    span {
      margin-left: 8px;
      color: $ornamentColor;

      i {
        color: $ornamentColor;
        font-size: 16px;
        margin-left: 2px;
      }
    }
    .not-buy {
      color: $fontColor1;
      i {
        color: $fontColor1;
      }
    }
  }
  .invalid {
    padding: 2px 8px;
    margin: 0 20px;
    border-radius: 20px;
    font-size: 12px;
    background-color: $fontColor1;
    color: $fontColor2;
  }
}
.car-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: $background;
  height: 58px;
  >div {
    margin: 0 50px;
  }
  .subtotalAmount{
    color: #ff0000;
    font-size: 12px;
    font-weight: 900;
    width: 160px;
    padding: 0 4px;
  }
  .button {
    width: 80px;
    line-height: 40px;
    text-align: center;
    background-color: #D7D7D7;
    color: #fff;
    cursor: default;
    &.active {
      background-color: #ff0000;
      cursor: pointer;
    }
  }
  .deng {
    width: 25px;
    height: 25px;
    background: url('../../assets/icon/deng.png') no-repeat top center;
    background-size: 100% 100%;
    cursor: pointer;
  }
}
</style>
